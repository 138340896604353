body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161616;
  color: #fff;
}

center {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

header {
  background: rgba(22, 22, 22, 0.75);
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  width: 80vw;
  max-width: 350px;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  border-radius: 20px;
}

textarea {
  padding: 8px;
}

button {
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 8px;
}
